import { useAvaContext } from "@doitintl/ava-components";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";

import { AvaHistorySelect } from "./AvaHistorySelect";
import { ChatToolbarHeader } from "./ChatToolbarHeader";

type Props = {
  onCloseClicked: () => void;
  hideHistory?: boolean;
};

export function ChatToolbar({ onCloseClicked, hideHistory }: Props) {
  const { setConversationId, conversationId } = useAvaContext();

  return (
    <Stack
      sx={{
        alignItems: "center",
        pb: hideHistory ? 0 : 2,
        borderBottom: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.paper",
      }}
    >
      <ChatToolbarHeader onCloseClicked={onCloseClicked} />
      {!hideHistory && (
        <Stack justifyContent="center" width="100%" px={2}>
          <Stack spacing={1} direction="row" alignItems="center" alignSelf="center" maxWidth={800} width="100%">
            <AvaHistorySelect />

            <IconButton
              onClick={() => {
                setConversationId(undefined);
              }}
              disabled={conversationId === undefined}
              sx={{ p: 1.1, border: "1px solid", borderColor: "divider", borderRadius: "4px" }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

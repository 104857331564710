import { type ChangeEvent, useEffect, useMemo } from "react";

import { type SupportServiceModel } from "@doitintl/cmp-models";
import { Box, Stack, Typography } from "@mui/material";

import CaseIQ from "../../Support/Components/CaseIQ/CaseIQ";
import { CaseIQClientProvider, setCaseIqSessionId } from "../../Support/Components/CaseIQ/utils";
import CategorySuggest from "../../Support/Components/CategorySuggest";
import Editor from "../../Support/Components/Editor/Editor";
import SupportPlatformDropdown from "../../Support/Components/SupportPlatformDropdown";
import { AssetSelector } from "./AssetSelector";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";
import { useCategoryList, usePlatformList } from "./hooks";
import { SeverityDropdown } from "./SeverityDropdown";

type Props = {
  bigWidth: boolean;
};

export const AvaCreateTicket = ({ bigWidth }: Props) => {
  const { caseIQState, handleCaseIQStateChange } = useAvaCaseIQContext();

  const { asset, cloudProduct, content, platform, urgency } = caseIQState;
  const platformList = usePlatformList();

  const categories = useCategoryList(platform);

  const categoryName = categories.find((category) => category.id === cloudProduct)?.name || "";

  const caseIqSessionId = useMemo(() => crypto.randomUUID(), []);
  useEffect(() => {
    setCaseIqSessionId(caseIqSessionId);
  }, [caseIqSessionId]);

  return (
    <Stack
      px={2}
      py={3}
      height="100%"
      alignItems="center"
      sx={{
        overflow: "auto",
      }}
      justifyContent={bigWidth ? "center" : "flex-start"}
    >
      <Stack width={bigWidth ? 980 : "auto"} alignItems={bigWidth ? "center" : "flex-start"}>
        <Typography variant="subtitle1" fontWeight={500}>
          How can we help you today?
        </Typography>
        <Typography variant="caption" color="text.secondary" pb={bigWidth ? 3 : 2}>
          Our Customer Reliability Engineers need additional details to assist you effectively
        </Typography>
      </Stack>
      <Stack spacing={2} direction={bigWidth ? "row" : "column"} width={bigWidth ? 980 : "auto"}>
        <Box width="inherit">
          <Editor
            placeholder="Please provide a detailed description of your issue"
            name="body"
            value={content}
            onChange={(content: string) => {
              handleCaseIQStateChange("content", content);
            }}
          />
        </Box>
        <Stack spacing={2} minWidth={bigWidth ? 380 : "auto"}>
          <SeverityDropdown
            onChange={(urgency: string) => {
              handleCaseIQStateChange("urgency", urgency);
            }}
            value={urgency}
          />
          <SupportPlatformDropdown
            platformsList={platformList}
            handleChangePlatformField={(e: ChangeEvent) => {
              handleCaseIQStateChange("platform", (e.target as HTMLSelectElement).value);
              handleCaseIQStateChange("cloudProduct", "");
            }}
            error={false}
            platform={platform}
            hideHelper
          />
          <CategorySuggest
            categories={categories}
            categoryTitle={categoryName}
            onChange={(cloudProduct: SupportServiceModel) => {
              handleCaseIQStateChange("cloudProduct", cloudProduct.id);
            }}
            error={false}
          />
          <AssetSelector
            onChange={(asset) => {
              handleCaseIQStateChange("asset", asset);
            }}
            selectedItem={asset}
            platform={platform}
          />

          <CaseIQClientProvider>
            <CaseIQ
              description={content}
              platform={platform}
              product={cloudProduct}
              subject=""
              setMinHeight={(_: number) => true}
              avaChat
            />
          </CaseIQClientProvider>
        </Stack>
      </Stack>
    </Stack>
  );
};

import { type ChangeEvent, type JSX, useMemo } from "react";

import { useAvaContext } from "@doitintl/ava-components";
import { TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import { type AvaConversationRef, useAvaConversationRefs } from "../Ava/useAvaConversationRefs";
import { avaPeriods } from "./consts";
import { useConversationHistory } from "./conversationsHook";
import { ConversationHistoryPeriod } from "./types";

export const AvaHistorySelect = () => {
  const [allConversations] = useAvaConversationRefs(false);

  const conversationHistory = useConversationHistory(false);

  const { setConversationId, conversationId } = useAvaContext();

  const handleChatHistoryChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    const conversationId = event.target.value;
    setConversationId(conversationId);
  };

  const newChatMode =
    Boolean(!conversationId) || allConversations.find((ref) => ref.conversationId === conversationId) === undefined;

  const options = useMemo<JSX.Element[]>(() => {
    const renderPeriodHeader = (period: string) => (
      <Typography
        variant="subtitle2"
        key={period}
        data-cy={`sectionHeader-${period}`}
        sx={{ my: 1, mx: 1.25, color: "text.secondary" }}
      >
        {avaPeriods[period]}
      </Typography>
    );

    const renderConversationItem = (period: string, conversation: AvaConversationRef) => (
      <MenuItem
        key={conversation.conversationId}
        value={conversation.conversationId}
        data-cy={`avaHistoryMenuItem-${period}-${conversation.conversationId}`}
      >
        {conversation.title}
      </MenuItem>
    );

    const result: JSX.Element[] = [];

    Object.values(ConversationHistoryPeriod).forEach((period) => {
      if (!conversationHistory[period]?.length) {
        return;
      }

      result.push(
        renderPeriodHeader(period),
        ...conversationHistory[period].map((conversation: AvaConversationRef) =>
          renderConversationItem(period, conversation)
        )
      );
    });

    return result;
  }, [conversationHistory]);

  return (
    <TextField
      select
      label="Chat History"
      value={newChatMode ? "" : conversationId}
      onChange={handleChatHistoryChanged}
      fullWidth
      data-cy="chat-history-select"
      placeholder="New chat"
      sx={{ alignSelf: "stretch" }}
      slotProps={{
        select: {
          MenuProps: {
            sx: { maxHeight: "460px", maxWidth: "300px", zIndex: 10001 },
          },
        },
      }}
    >
      {options}
    </TextField>
  );
};

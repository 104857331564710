import { useRef } from "react";

import { Slide } from "react-awesome-reveal";
import { type MessageAva, type MessageUser } from "@doitintl/ava-components";
import { useHistoryToShow } from "@doitintl/ava-components/src/Messenger/hooks";
import { Stack } from "@mui/material";

import { useAvaConversation } from "../Ava/useAvaConversation";
import { AvaLoading } from "./AvaLoading";
import { MessageAvaComponent } from "./MessageAvaComponent";
import { MessageUserComponent } from "./MessageUserComponent";
import { PresetFooter } from "./PresetFooter";
import { useScroll } from "./scrollHooks";

type Props = {
  conversationId?: string;
  handleAskQuestion: (question: string) => Promise<void>;
  messageUserInProgress: MessageUser;
  processingQuestion: boolean;
  messageAvaInProgress: MessageAva;
  error?: string;
  isGeneratingReport: boolean;
  newQuestionAsked: boolean;
};

export const MessageList = ({
  conversationId,
  handleAskQuestion,
  messageUserInProgress,
  processingQuestion,
  messageAvaInProgress,
  error,
  isGeneratingReport,
  newQuestionAsked,
}: Props) => {
  const stackRef = useRef<HTMLDivElement>(null);

  const { handleScroll } = useScroll(stackRef, messageAvaInProgress);

  const [conversationHistory, loadingHistory] = useAvaConversation(conversationId);

  const historyToShow = useHistoryToShow(
    messageUserInProgress.content,
    processingQuestion,
    conversationHistory,
    messageAvaInProgress.answerId,
    newQuestionAsked
  );

  const handleRetryClicked = async () => {
    const question = messageUserInProgress.content;
    if (question.length > 0) {
      await handleAskQuestion(question);
    }
  };

  if (loadingHistory && !processingQuestion) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ height: "100%", flex: 1 }}>
        <AvaLoading />
      </Stack>
    );
  }

  if (!conversationId && !processingQuestion) {
    return <PresetFooter onAskQuestion={handleAskQuestion} />;
  }

  return (
    <Stack
      onScroll={handleScroll}
      ref={stackRef}
      sx={{ overflow: "hidden auto", scrollbarWidth: "none", px: 0, py: 2 }}
    >
      {historyToShow.map((message) => {
        if (message.role === "user") {
          return <MessageUserComponent key={message.createdAt.getTime()} message={message} disableAnimation={true} />;
        }
        if (message.role === "ava") {
          return (
            <MessageAvaComponent
              key={message.createdAt.getTime()}
              message={message}
              disableAnimation={true}
              handleAskQuestion={handleAskQuestion}
            />
          );
        }
      })}
      {newQuestionAsked && (
        <Slide direction="up" duration={300} triggerOnce>
          <MessageUserComponent message={messageUserInProgress} disableAnimation={false} />
          <MessageAvaComponent
            message={messageAvaInProgress}
            disableAnimation={false}
            handleAskQuestion={handleAskQuestion}
            onRetryClicked={handleRetryClicked}
            showError={error}
            isGeneratingReport={isGeneratingReport}
          />
        </Slide>
      )}
    </Stack>
  );
};

import { type AvaConversationRef } from "../Ava/useAvaConversationRefs";

export enum ConversationHistoryPeriod {
  TODAY = "today",
  PREV_7 = "prev7",
  PREV_30 = "prev30",
  OLDER = "older",
}

export type ConversationHistory = Partial<Record<ConversationHistoryPeriod, AvaConversationRef[]>>;

import React, { type ReactNode } from "react";

import { BarChart } from "@mui/icons-material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Card, Stack, Typography } from "@mui/material";

type PresetQuestion = {
  displayQuestion: string;
  detailedQuestion: string;
  icon: ReactNode;
};

type Props = {
  onAskQuestion: (question: string) => void;
};

export const PresetPrompts = ({ onAskQuestion }: Props) => {
  const presetQuestions: PresetQuestion[] = [
    {
      displayQuestion: "Show me my top 3 services by spend for the past month",
      detailedQuestion: "Generate a report showing my top 3 services by spend in the past month",
      icon: <BarChart fontSize="small" color="primary" />,
    },
    {
      displayQuestion: "How do I run workloads on EC2 Spot Instances?",
      detailedQuestion:
        "Provide detailed information about how DoiT Spot Scaling can help me run workloads reliably on EC2 Spot Instances",
      icon: <ChatBubbleOutlineIcon fontSize="small" color="primary" />,
    },
    {
      displayQuestion: "Show my top services by spend in the last 2 weeks",
      detailedQuestion: "Show my top services by spend in the last 2 weeks",
      icon: <BarChart fontSize="small" color="primary" />,
    },
    {
      displayQuestion: "How do I grant DoiT Console access to my colleagues?",
      detailedQuestion: "Explain the steps to invite a new user to the DoiT console",
      icon: <ChatBubbleOutlineIcon fontSize="small" color="primary" />,
    },
  ];

  return (
    <Stack spacing={2} alignItems="center" px={2}>
      {presetQuestions.map((question) => (
        <Card
          key={question.displayQuestion}
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: 2,
            borderColor: "divider",
            cursor: "pointer",
            "&:hover": {
              borderColor: "primary.main",
            },
            "& .MuiCardContent-root": {
              color: "primary.main",
            },
          }}
          onClick={() => {
            onAskQuestion(question.detailedQuestion);
          }}
        >
          <Stack direction="row" spacing={2} p={2} alignItems="center">
            {question.icon}
            <Typography variant="body2" color="text.secondary">
              {question.displayQuestion}
            </Typography>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

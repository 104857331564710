import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { useAvaContext } from "@doitintl/ava-components";
import {
  AppModel,
  AssetModel,
  AssetTypeGoogleCloudProject,
  CustomerSecurityMode,
  IntegrationModel,
} from "@doitintl/cmp-models";
import { getCollection, useCollectionData, useDocumentData } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../Context/CustomerContext";
import { getProductsProperties, platformName } from "../../Support/utils";

export const useNewQuestionAsked = () => {
  const [prevConversationId, setPrevConversationId] = useState<string>();
  const [newQuestionAsked, setNewQuestionAsked] = useState(false);
  const { conversationId } = useAvaContext();

  useEffect(() => {
    if (conversationId !== prevConversationId) {
      if (prevConversationId) {
        setNewQuestionAsked(false);
      }
      setPrevConversationId(conversationId);
    }
  }, [conversationId, setNewQuestionAsked, prevConversationId]);

  return {
    newQuestionAsked,
    setNewQuestionAsked,
  };
};

export const useConversationIdChanged = (callback: () => void) => {
  const [prevConversationId, setPrevConversationId] = useState<string>();
  const { conversationId } = useAvaContext();

  useEffect(() => {
    if (conversationId !== prevConversationId) {
      setPrevConversationId(conversationId);
      callback();
    }
  }, [conversationId, setPrevConversationId, prevConversationId, callback]);

  return prevConversationId;
};

export function useIsBigWidth() {
  const ref = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number>(0);

  useLayoutEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver(([entry]) => {
        setWidth(entry.contentRect.width);
      });
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return { ref, bigWidth: width > 980 };
}

export const usePlatformList = () => {
  const ref = getCollection(IntegrationModel).doc("zendesk").collection("ticketFields").doc("platforms");
  const [ticketsFields] = useDocumentData(ref);
  return ticketsFields?.values || [];
};

export const useCategoryList = (selectedPlatform: string) => {
  const ref = getCollection(AppModel).doc("support").collection("services").where("blacklisted", "==", false);
  const [categories] = useCollectionData(ref);
  if (selectedPlatform) {
    return categories?.filter((category) => category.platform === platformName(selectedPlatform)) || [];
  } else {
    return [];
  }
};

export const useUserAssets = (platform: string) => {
  const { customer } = useCustomerContext({ allowNull: true });

  let assetsQuery = getCollection(AssetModel).where("customer", "==", customer?.ref);
  if (customer?.securityMode === CustomerSecurityMode.RESTRICTED) {
    assetsQuery = assetsQuery.where("type", "!=", AssetTypeGoogleCloudProject);
  }

  const [assets] = useCollectionData(assetsQuery);

  return useMemo(() => {
    const parsedAssets = assets?.map((asset) => getProductsProperties(asset)) || [];
    const filteredAssets =
      parsedAssets?.filter((asset) => asset !== null && platformName(platform) === asset.asset) || [];

    // delete the duplicates
    const seen = new Set();
    const filteredAssetsNoDuplicates = filteredAssets.filter((el) => {
      const duplicate = seen.has(el.identifier);
      seen.add(el.identifier);
      return !duplicate;
    });
    return filteredAssetsNoDuplicates;
  }, [assets, platform]);
};

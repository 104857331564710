import { useState } from "react";

import { useAvaContext } from "@doitintl/ava-components";
import { Box, Button, Stack, Typography } from "@mui/material";

import { avaPeriods } from "./consts";
import { useConversationHistory } from "./conversationsHook";
import { MoreOptionsMenu } from "./MoreOptionsMenu";

export const ChatHistory = () => {
  const history = useConversationHistory(true);
  const [hoveredConversationId, setHoveredConversationId] = useState<string | null>(null);

  const { setConversationId } = useAvaContext();

  return (
    <Stack sx={{ borderRight: "1px solid", borderColor: "divider" }}>
      <Stack spacing={3} width={300} py={2} sx={{ overflowY: "auto", height: "100%" }}>
        <Typography variant="subtitle2" fontWeight={500} pb={0.5} px={2}>
          Chat history
        </Typography>
        {Object.entries(history).map(([period, conversations]) => (
          <Stack key={period}>
            <Typography variant="caption" fontWeight={500} px={2}>
              {avaPeriods[period]}
            </Typography>
            {conversations.map((conversation) => (
              <Stack
                key={conversation.conversationId}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                onMouseEnter={() => {
                  setHoveredConversationId(conversation.conversationId);
                }}
                onMouseLeave={() => {
                  setHoveredConversationId(null);
                }}
                spacing={0.5}
                height={32}
                sx={{
                  pl: 2.5,
                  pr: 2,
                  ":hover": {
                    backgroundColor: "primary.hoverBackground",
                  },
                }}
              >
                <Typography
                  variant="body2"
                  onClick={() => {
                    setConversationId(conversation.conversationId);
                  }}
                  sx={{ cursor: "pointer", py: "9px" }}
                >
                  {conversation.title.length > 28 ? `${conversation.title.slice(0, 28)}...` : conversation.title}
                </Typography>
                {hoveredConversationId === conversation.conversationId && (
                  <MoreOptionsMenu conversationId={conversation.conversationId} />
                )}
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
      <Box
        px={2}
        py={2.75}
        sx={{
          borderTop: "1px solid",
          borderColor: "divider",
          backgroundColor: "background.default",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "-webkit-fill-available",
          }}
          onClick={() => {
            setConversationId();
          }}
        >
          Start new chat
        </Button>
      </Box>
    </Stack>
  );
};

import { useCallback } from "react";

import { useAvaContext } from "@doitintl/ava-components";

import { useAvaDialogContext } from "../../Ava2/AvaDialogContextProvider";
import { AvaFullscreen } from "../../Ava2/AvaFullscreen";
import { AvaResizableChat } from "../../Ava2/AvaResizableChat";

export const AVADialog = () => {
  const { isFullscreenMode, handleCloseAvaDialog } = useAvaDialogContext();
  const { setConversationId } = useAvaContext();

  const closeDialog = useCallback(() => {
    handleCloseAvaDialog();
    setConversationId(undefined);
  }, [handleCloseAvaDialog, setConversationId]);

  if (isFullscreenMode) {
    return <AvaFullscreen closeDialog={closeDialog} />;
  }

  return <AvaResizableChat closeDialog={closeDialog} />;
};

import { createContext, type ReactNode, useContext, useState } from "react";

import noop from "lodash/noop";

import { useAvaDialogContext } from "../../../../../client/src/Components/Ava2/AvaDialogContextProvider";
import { AvaMessageHandlersProvider } from "./AvaMessageHandlersProvider";

type ContextProps = {
  fetcher: (data: any) => Promise<Response>;
  conversationId?: string;
  setConversationId: (conversationId?: string) => void;
};

const defaultValue: ContextProps = {
  fetcher: noop as any,
  conversationId: undefined,
  setConversationId: noop,
};

const avaContext = createContext(defaultValue);

export const AvaContextProvider = ({
  children,
  fetcher,
}: {
  children: ReactNode;
  fetcher: (data: any) => Promise<Response>;
  dashboardId?: string;
}) => {
  const { setDashboardId } = useAvaDialogContext();
  const [conversationId, setConversationId] = useState<string | undefined>();

  const handleChangeConversationId = (newConversationId?: string) => {
    setConversationId(newConversationId);
    if (conversationId !== newConversationId) {
      setDashboardId("");
    }
  };

  return (
    <AvaMessageHandlersProvider>
      <avaContext.Provider
        value={{
          fetcher,
          conversationId,
          setConversationId: handleChangeConversationId,
        }}
      >
        {children}
      </avaContext.Provider>
    </AvaMessageHandlersProvider>
  );
};

export const useAvaContext = () => useContext(avaContext);

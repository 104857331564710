import { createContext, type FC, type ReactNode, useContext } from "react";

import { useLiveAPI, type UseLiveAPIResults } from "../Components/Ava2/Screenshare/hooks/use-live-api";

const host = "generativelanguage.googleapis.com";
const url = `wss://${host}/ws/google.ai.generativelanguage.v1alpha.GenerativeService.BidiGenerateContent`;

const LiveAPIContext = createContext<UseLiveAPIResults | undefined>(undefined);

export type LiveAPIProviderProps = {
  children: ReactNode;
};

export const LiveAPIProvider: FC<LiveAPIProviderProps> = ({ children }) => {
  const apiKey = `${process.env.REACT_APP_GEMINI_API_KEY}`;
  const liveAPI = useLiveAPI({ url, apiKey });

  return <LiveAPIContext.Provider value={liveAPI}>{children}</LiveAPIContext.Provider>;
};

export const useAvaLiveAPIContext = () => {
  const context = useContext(LiveAPIContext);
  if (!context) {
    throw new Error("useLiveAPIContext must be used wihin a LiveAPIProvider");
  }
  return context;
};

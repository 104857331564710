import { Autocomplete, TextField } from "@mui/material";

import { usePlatformList, useUserAssets } from "./hooks";

type Props = {
  onChange: (data: string) => void;
  selectedItem: string;
  platform: string;
};

export const AssetSelector = ({ onChange, selectedItem, platform }: Props) => {
  const assets = useUserAssets(platform);

  const platformList = usePlatformList();
  const label = platformList.find((p) => p.value === platform)?.label || "";

  return (
    <Autocomplete
      options={assets}
      getOptionLabel={(option) => option.identifier || ""}
      fullWidth
      disablePortal
      size="medium"
      sx={{ mt: 2, mb: 2 }}
      onChange={(_, newValue: string) => {
        onChange(newValue);
      }}
      value={selectedItem}
      renderInput={(params) => (
        <TextField
          {...params}
          size="medium"
          label={label}
          required
          variant="outlined"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
      )}
    />
  );
};

import { type ChangeEvent, useRef, useState } from "react";

import { AskButton } from "@doitintl/ava-components/src/Messenger/AskButton";
import { StopGenerationButton } from "@doitintl/ava-components/src/Messenger/StopGenerationButton";
import { Stack, TextField } from "@mui/material";

import { useConversationIdChanged } from "./hooks";
import { StreamControl } from "./Screenshare/StreamControl";

type Props = {
  disableInput: boolean;
  onAskQuestionClicked: (question: string) => Promise<void>;
  showStopButton: boolean;
  handleStopGeneration: () => void;
};

export const MessengerFooter = ({
  disableInput,
  onAskQuestionClicked,
  showStopButton,
  handleStopGeneration,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [question, setQuestion] = useState("");

  useConversationIdChanged(() => {
    ref.current?.focus();
  });

  const handleAskQuestion = async () => {
    if (question.trim().length === 0) {
      return;
    }
    onAskQuestionClicked(question.trim());
    setQuestion("");
  };

  return (
    <Stack
      sx={{
        backgroundColor: "background.paper",
        px: 2,
        py: 1.5,
        borderTopWidth: 1,
        borderTopColor: "divider",
        borderTopStyle: "solid",
        mt: 0,
      }}
    >
      <TextField
        inputRef={ref}
        fullWidth
        multiline
        autoFocus
        maxRows={5}
        label="Ask Ava"
        value={question}
        disabled={disableInput && !showStopButton}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey && question) {
            if (showStopButton) {
              event.preventDefault();
              handleStopGeneration();
              return;
            }
            return handleAskQuestion();
          }
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            paddingRight: 2,
          },
          "& .MuiInputLabel-shrink": {
            mt: 0,
          },
          maxWidth: 800,
          alignSelf: "center",
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (!showStopButton) {
            setQuestion(event.target.value);
          } else {
            setQuestion("");
          }
        }}
        data-cy="ask-question-input"
        slotProps={{
          inputLabel: {
            sx: { mt: "5px" },
          },
          input: {
            endAdornment: !showStopButton ? (
              <AskButton handleAskQuestion={handleAskQuestion} question={question} processingQuestion={disableInput} />
            ) : (
              <StopGenerationButton handleStopGeneration={handleStopGeneration} />
            ),
          },
        }}
      />
      <StreamControl />
    </Stack>
  );
};

import { createContext, type ReactNode, useContext, useState } from "react";

import noop from "lodash/noop";

type CaseIQState = {
  content: string;
  platform: string;
  cloudProduct: string;
  asset: string;
  urgency: string;
};

type AvaDialogContextType = {
  caseIQConversationId: string;
  setCaseIQConversationId: (caseIQConversationId: string) => void;
  caseIQState: CaseIQState;
  handleCaseIQStateChange: (field: string, value: string) => void;
};

type Props = {
  children: ReactNode;
};

const emptyState = {
  content: "",
  platform: "",
  cloudProduct: "",
  asset: "",
  urgency: "",
};

export const AvaCaseIQContext = createContext<AvaDialogContextType>({
  caseIQConversationId: "",
  setCaseIQConversationId: noop,
  caseIQState: emptyState,
  handleCaseIQStateChange: noop,
});

export const AvaCaseIQProvider = ({ children }: Props) => {
  const [caseIQConversationId, setCaseIQConversationId] = useState("");
  const [caseIQState, setCaseIQState] = useState<CaseIQState>(emptyState);

  const handleCaseIQStateChange = (field: string, value: string) => {
    setCaseIQState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleCaseIQConversationIdChange = (caseIQConversationId: string) => {
    if (!caseIQConversationId) {
      setCaseIQState(emptyState);
      setCaseIQConversationId("");
      return;
    }

    // bring here the result of ava initial state based on the conversation id
    const caseIQState = emptyState; // replace this with the actual state

    setCaseIQState(caseIQState);
    setCaseIQConversationId(caseIQConversationId);
  };

  return (
    <AvaCaseIQContext.Provider
      value={{
        caseIQConversationId,
        setCaseIQConversationId: handleCaseIQConversationIdChange,
        caseIQState,
        handleCaseIQStateChange,
      }}
    >
      {children}
    </AvaCaseIQContext.Provider>
  );
};

export const useAvaCaseIQContext = () => useContext(AvaCaseIQContext);

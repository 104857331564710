import { useAvaContext } from "@doitintl/ava-components";
import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreen from "@mui/icons-material/CloseFullscreen";
import MinimizeIcon from "@mui/icons-material/Minimize";
import OpenInFull from "@mui/icons-material/OpenInFull";
import { IconButton, Stack, Typography } from "@mui/material";

import { AvaIcon } from "../Ava/Messenger/AvaIcon";
import { useAvaDialogContext } from "./AvaDialogContextProvider";
import { MoreOptionsMenu } from "./MoreOptionsMenu";

type Props = {
  readonly onCloseClicked: () => void;
};

export const ChatToolbarHeader = ({ onCloseClicked }: Props) => {
  const { toggleFullscreenMode, isFullscreenMode, setIsAvaDialogMinimized } = useAvaDialogContext();
  const { conversationId } = useAvaContext();

  const fullscreenStyles = isFullscreenMode ? { borderBottom: "1px solid", borderColor: "divider" } : {};

  return (
    <Stack
      width="100%"
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        pl: 2,
        pr: 1,
        ...fullscreenStyles,
      }}
    >
      <Stack direction="row" spacing={1} py={2}>
        <AvaIcon />
        <Typography variant="subtitle1" fontWeight={500}>
          Ava - AI assistant
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        {!isFullscreenMode && <MoreOptionsMenu conversationId={conversationId} isSideChatMenu />}
        <IconButton
          onClick={() => {
            setIsAvaDialogMinimized(true);
          }}
        >
          <MinimizeIcon />
        </IconButton>
        <IconButton onClick={toggleFullscreenMode}>
          {isFullscreenMode ? <CloseFullscreen /> : <OpenInFull />}
        </IconButton>
        <IconButton onClick={onCloseClicked}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

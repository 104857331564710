import { type ReactNode, type RefObject, useEffect, useRef, useState } from "react";

import { Avatar, Chip } from "@mui/material";
import { Stack } from "@mui/system";
import cn from "classnames";

import { useAuthContext } from "../../../Context/AuthContext";
import { useAvaLiveAPIContext } from "../../../Context/AvaLiveAPIContext";
import { useScreenCapture } from "./hooks/use-screen-capture";
import { AudioRecorder } from "./lib/audio-recorder";

export type ControlTrayProps = {
  videoRef: RefObject<HTMLVideoElement>;
  children?: ReactNode;
  supportsVideo: boolean;
  onVideoStreamChange?: (stream: MediaStream | null) => void;
};

// create a new component named StreamControl
export const StreamControl = () => {
  const { currentUser } = useAuthContext();
  const renderCanvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const screenCapture = useScreenCapture();
  const [audioRecorder] = useState(() => new AudioRecorder());
  const [activeVideoStream, setActiveVideoStream] = useState<MediaStream | null>(null);
  const [inVolume, setInVolume] = useState(0);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const { client, connected, connect, disconnect } = useAvaLiveAPIContext();

  // Audio stream
  useEffect(() => {
    const onData = (base64: string) => {
      client.sendRealtimeInput([
        {
          mimeType: "audio/pcm;rate=16000",
          data: base64,
        },
      ]);
    };
    if (connected && audioRecorder) {
      audioRecorder.on("data", onData).on("volume", setInVolume).start();
    } else {
      audioRecorder.stop();
    }
    return () => {
      audioRecorder.off("data", onData).off("volume", setInVolume);
    };
  }, [connected, client, audioRecorder]);

  // Video stream
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = activeVideoStream;
    }

    let timeoutId = -1;

    function sendVideoFrame() {
      const video = videoRef.current;
      const canvas = renderCanvasRef.current;

      if (!video || !canvas) {
        return;
      }

      const ctx = canvas.getContext("2d")!;
      canvas.width = video.videoWidth * 0.25;
      canvas.height = video.videoHeight * 0.25;
      if (canvas.width + canvas.height > 0) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const base64 = canvas.toDataURL("image/jpeg", 1.0);
        const data = base64.slice(base64.indexOf(",") + 1, Infinity);
        client.sendRealtimeInput([{ mimeType: "image/jpeg", data }]);
      }
      if (connected) {
        timeoutId = window.setTimeout(sendVideoFrame, 1000 / 0.5);
      }
    }
    if (connected && activeVideoStream !== null) {
      requestAnimationFrame(sendVideoFrame);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [connected, activeVideoStream, client, videoRef]);

  useEffect(() => {
    document.documentElement.style.setProperty("--volume", `${Math.max(5, Math.min(inVolume * 200, 8))}px`);
  }, [inVolume]);

  const handleConnect = async () => {
    if (connected) {
      screenCapture.stop();
      await disconnect();
      return;
    }
    console.log("Connecting to Gemini API");
    await connect();
    const mediaStream = await screenCapture.start();
    setActiveVideoStream(mediaStream);
    setVideoStream(mediaStream);
    client.send({
      text: `Hey, my name is ${currentUser?.displayName?.split(" ")?.[0]}, who are you?`,
    });
  };

  return (
    <Stack sx={{ mt: 1 }}>
      <canvas style={{ display: "none" }} ref={renderCanvasRef} />
      <video
        className={cn("stream", {
          hidden: !videoRef.current || !videoStream,
        })}
        style={{ display: "none" }}
        ref={videoRef}
        autoPlay
        playsInline
      />
      <Chip
        color={!connected ? "secondary" : "error"}
        sx={{ width: !connected ? 100 : 114, justifyContent: "flex-start" }}
        onClick={handleConnect}
        label={connected ? "Disconnect" : "Live Ava"}
        clickable
        avatar={<Avatar />}
      />
    </Stack>
  );
};

import { Paper, Stack } from "@mui/material";

import { AvaCaseIQ } from "./AvaCaseIQ";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";
import { ChatHistory } from "./ChatHistory";
import { ChatToolbarHeader } from "./ChatToolbarHeader";
import { Messenger } from "./Messenger";

type Props = {
  closeDialog: () => void;
};

export const AvaFullscreen = ({ closeDialog }: Props) => {
  const { caseIQConversationId } = useAvaCaseIQContext();

  return (
    <Paper
      sx={{
        position: "fixed",
        top: 10,
        left: 10,
        height: "98vh",
        right: 10,
        zIndex: 10000,
        border: "1px solid",
        borderColor: "divider",
        display: "flex",
        boxShadow: 24,
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "general.backgroundDark",
      }}
    >
      <ChatToolbarHeader onCloseClicked={closeDialog} />
      {caseIQConversationId ? (
        <AvaCaseIQ />
      ) : (
        <Stack sx={{ flexDirection: "row", height: "92vh" }}>
          <ChatHistory />
          <Messenger />
        </Stack>
      )}
    </Paper>
  );
};

import { Button, Stack } from "@mui/material";

import { AvaCreateTicket } from "./AvaCreateTicket";
import { useIsBigWidth } from "./hooks";

export const AvaCaseIQ = () => {
  const { ref, bigWidth } = useIsBigWidth();

  return (
    <Stack
      ref={ref}
      height="100%"
      justifyContent="space-between"
      sx={{
        overflow: "auto",
      }}
    >
      <AvaCreateTicket bigWidth={bigWidth} />
      <Stack
        p={1}
        alignItems={bigWidth ? "center" : "flex-end"}
        sx={{ borderTop: "1px solid", borderColor: "divider", backgroundColor: "background.paper" }}
      >
        <Stack width={bigWidth ? 980 : "auto"} alignItems="flex-end">
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "fit-content" }}
            onClick={() => {}}
            // disabled={!content || !platform || !cloudProduct || !asset}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

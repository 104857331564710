import { useCallback, useState } from "react";

import { useAvaContext } from "@doitintl/ava-components";
import { useMessagesInProgress } from "@doitintl/ava-components/src/Messenger/hooks";
import { useAva } from "@doitintl/ava-components/src/useAva";
import { Stack } from "@mui/material";

import { useCustomerContext } from "../../Context/CustomerContext";
import { useMetadata } from "../Ava/Messenger/hooks";
import LoadingCustomData from "../Ava/Messenger/LoadingCustomData";
import { useAvaDialogContext } from "./AvaDialogContextProvider";
import { useNewQuestionAsked } from "./hooks";
import { MessageList } from "./MessageList";
import { MessengerFooter } from "./MessengerFooter";

export const Messenger = () => {
  const [processingQuestion, setProcessingQuestion] = useState(false);
  const [generationStopped, setGenerationStopped] = useState(false);

  const [error, setError] = useState<string | undefined>();
  const [question, setQuestion] = useState("");

  const { customer } = useCustomerContext({ allowNull: true });

  const { updateCustomerMetadata } = useMetadata();
  const { conversationId, setConversationId } = useAvaContext();
  const { newQuestionAsked, setNewQuestionAsked } = useNewQuestionAsked();
  const { setConversationHasOccurred } = useAvaDialogContext();

  const handleFinishGeneration = useCallback(() => {
    setProcessingQuestion(false);
    setGenerationStopped(false);
  }, []);
  const getAbortStatus = useCallback(() => generationStopped, [generationStopped]);

  const { answer, answerExtras, askQuestion, onStopGeneration, overrideLongerWait } = useAva(
    handleFinishGeneration,
    "console",
    getAbortStatus
  );

  const { messageUserInProgress, messageAvaInProgress } = useMessagesInProgress(
    generationStopped,
    question,
    answer,
    answerExtras
  );

  const handleStopGeneration = useCallback(() => {
    setGenerationStopped(true);
    setProcessingQuestion(false);
    if (conversationId && answerExtras?.answerId) {
      onStopGeneration(conversationId, answerExtras?.answerId, answer);
    }
  }, [conversationId, answerExtras?.answerId, answer, onStopGeneration]);

  const handleAskQuestion = async (question: string) => {
    setQuestion(question);
    setProcessingQuestion(true);
    setError(undefined);
    setGenerationStopped(false);
    setNewQuestionAsked(true);

    try {
      await askQuestion({
        conversationId,
        question,
        conversationCallback: (conversationId) => {
          setConversationId(conversationId);
          setConversationHasOccurred(true);
          updateCustomerMetadata();
        },
      });
    } catch (e: unknown) {
      setError(String(e));
    }
  };

  return (
    <Stack
      sx={{
        backgroundColor: "general.backgroundDark",
        justifyContent: "flex-end",
        overflow: "auto",
        height: "100%",
        width: "100%",
      }}
    >
      <MessageList
        conversationId={conversationId}
        messageUserInProgress={messageUserInProgress}
        messageAvaInProgress={messageAvaInProgress}
        handleAskQuestion={handleAskQuestion}
        isGeneratingReport={overrideLongerWait}
        processingQuestion={processingQuestion}
        error={error}
        newQuestionAsked={newQuestionAsked}
      />

      {customer?.id && <LoadingCustomData customerId={customer.id} />}
      <MessengerFooter
        onAskQuestionClicked={handleAskQuestion}
        disableInput={processingQuestion}
        showStopButton={processingQuestion && !generationStopped && !!answerExtras?.answerId}
        handleStopGeneration={handleStopGeneration}
      />
    </Stack>
  );
};

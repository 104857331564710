import { useEffect, useRef } from "react";

import { Box, Stack, useTheme } from "@mui/material";
import { Resizable } from "re-resizable";

import { useFullScreen } from "../../utils/dialog";
import { AvaCaseIQ } from "./AvaCaseIQ";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";
import { useAvaDialogContext } from "./AvaDialogContextProvider";
import { ChatToolbar } from "./ChatToolbar";
import { Messenger } from "./Messenger";

type Props = {
  closeDialog: () => void;
};

export const AvaResizableChat = ({ closeDialog }: Props) => {
  const ref = useRef<Resizable>(null);

  const { isAvaDialogMinimized } = useAvaDialogContext();
  const { caseIQConversationId } = useAvaCaseIQContext();
  const theme = useTheme();
  const { isMobile } = useFullScreen();

  useEffect(() => {
    const currentSize = ref.current?.size.height;
    if (caseIQConversationId && currentSize && currentSize < 900) {
      ref.current?.updateSize({ height: 900 });
    }
  }, [caseIQConversationId]);

  return (
    <Box
      sx={{
        position: "absolute",
        display: !isAvaDialogMinimized ? "flex" : "none",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        zIndex: 10000,
        width: isMobile ? "100%" : "98.5vw",
        height: "100%",
        pointerEvents: "none",
      }}
    >
      <Resizable
        ref={ref}
        style={{
          position: "fixed",
          pointerEvents: "auto",
          border: "1px solid",
          borderColor: theme.palette.divider,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          backgroundColor: theme.palette.general.backgroundDark,
        }}
        defaultSize={{
          width: window.innerWidth < 550 ? window.innerWidth : "550px",
          height: caseIQConversationId ? 900 : "700px",
        }}
        maxHeight="90vh"
        maxWidth="90vw"
        minHeight={250}
        minWidth={300}
        enable={{ top: true, right: false, bottom: false, left: true, topLeft: true }}
      >
        <Stack justifyContent="space-between" height="100%">
          <ChatToolbar onCloseClicked={closeDialog} hideHistory={!!caseIQConversationId} />
          {caseIQConversationId ? <AvaCaseIQ /> : <Messenger />}
        </Stack>
      </Resizable>
    </Box>
  );
};

import { type ReactNode } from "react";

import { AvaContextProvider, AvaDialogContextProvider, TrpcContextProvider } from "@doitintl/ava-components";

import { useFetcher } from "../Ava/Messenger/hooks";
import { AvaCaseIQProvider } from "./AvaCaseIQProvider";

type Props = {
  children: ReactNode;
};

export const AVAProviders = ({ children }: Props) => {
  const fetcher = useFetcher();

  return (
    <AvaCaseIQProvider>
      <AvaDialogContextProvider>
        <AvaContextProvider fetcher={fetcher}>
          <TrpcContextProvider value={{ baseURL: window.location.origin }}>{children}</TrpcContextProvider>
        </AvaContextProvider>
      </AvaDialogContextProvider>
    </AvaCaseIQProvider>
  );
};
